<template>
  <el-container style="height:calc(100vh);">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      分类管理
      <span class="right" @click="editOrAdd('新增',{id:''})">新增分类</span>
    </el-header>
    <el-container style="height:calc(100vh);">
      <el-container>
        <el-main class="main">
          <div class="list-box">
            <div class="list" v-for="(item,i) in classList" :key="i">
              <div class="left el-icon-menu">
                {{ item.name }}<span></span>
              </div>
              <div class="right">
                <!-- <template>
                  <router-link to="/service-management" class="one el-icon-view">查看服务</router-link>
                </template> -->
                <div class="two el-icon-edit" @click="editOrAdd('编辑',item)">编辑分类</div>
                <div class="three el-icon-delete" @click="delAttr('删除',item)">删除分类</div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog :title="dialogTitle+'分类'" center :visible.sync="editOrAddShow" width="40%" :before-close="handleClose">
      <el-form label-position="left" label-width="70px" :model="form">
        <el-form-item :label="'分类'+'名称'">
          <el-input v-model="form.serviceName" :placeholder="'输入'+'分类'+'名称'"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="'分类'+'描述'">
          <el-input v-model="form.serviceDes" :placeholder="'输入'+'分类'+'描述'"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editOrAddShow = false">取 消</el-button>
        <el-button type="primary" @click="addCalss">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        editOrAddShow: false,
        dialogTitle: '',
        form: {
          serviceName: '',
          serviceDes: '',
        },
        itemdata:{},//选中的列表
        classList:[],//分类列表
      }
    },
    created() {},
    mounted(){
      this.getClassList()
    },
    methods: {
      //添加分类列表
      addCalss(){
        let type = null
        if(this.dialogTitle == "新增"){
          type=1
        }else if(this.dialogTitle == "编辑"){
          type=2
        }else if(this.dialogTitle == "删除"){
          type=3
        }
        this.$http.post('api/store/server/addshopcate',{
          type:type,
          name:this.form.serviceName,
          id:this.itemdata.id
        }).then(res =>{
          this.$message.success(res.data.msg)
          this.editOrAddShow = false
          this.form.serviceName = ''
          this.getClassList()
        })
      },
      //获取分类列表
      getClassList(){
        this.$http.post('api/store/server/servercate').then(res =>{
          this.classList = res.data.data
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      editOrAdd(typeName,item) {
        this.editOrAddShow = true
        this.dialogTitle = typeName
        this.itemdata = item
      },
      // 删除分类
      delAttr(typeName,item) {
        this.dialogTitle = typeName
        this.itemdata = item
        this.addCalss()
      },
      handleClose() {
        this.editOrAddShow = false
      },
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    height: 50px !important;
    color: #333;
    border-bottom: 1px solid #e9e9e9;

    .left {
      color: #46a6ff;
      font-size: 24px;
    }

    .right {
      color: #46a6ff;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .main {
    font-size: 12px;

    .list-box {
      padding: 10px;
      box-sizing: border-box;
      background-color: #f9f9f9;
      height: calc(100vh - 80px);

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666;
        font-size: 12px;
        background-color: #fff;
        margin-bottom: 10px;
        padding: 5px 8px;

        .left,
        .right {
          display: flex;
        }

        .left {
          justify-content: flex-start;

          &::before {
            margin-right: 10px;
          }

          span {
            margin-left: 10px;
            font-size: 12px;
            color: #999;
          }
        }

        .right {
          justify-content: flex-end;

          .zero,
          .one,
          .two,
          .three {
            margin: 10px;
            color: #46a6ff;

            &::before {
              margin-right: 2px;
            }
          }
        }

      }
    }
  }

  /deep/ .el-dialog {
    min-width: 400px;
    padding: 0 10px;

    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  .main.el-main {
    padding: 0 0px !important;
  }

</style>
